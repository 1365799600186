<template>
    <div class="user-profile">
        <h4 class="title is-4">{{$t('User profile')}}</h4>

        <user-profile-form :user="user" class="user-profile-form"/>

        <b-field class="field">
            <b-switch v-model="hasOrganizerProfile" @input="toggleOrganizerProfile">{{$t('I\'m organizer')}}</b-switch>
        </b-field>

        <organizer-profile-form v-if="user.organizer_profile" :user="user" class="user-profile-form"/>

        <update-password-form class="user-profile-form" />

    </div>

</template>

<script>
    import UserProfileForm from "../components/Forms/UserProfileForm";
    import OrganizerProfileForm from "../components/Forms/OrganizerProfileForm";
    import UpdatePasswordForm from "../components/Forms/UpdatePasswordForm";
    import formHelperMixin from "../mixins/formHelperMixin";
    import {mapMutations, mapState} from "vuex";
    import {SET_USER} from "@/store/modules/auth/mutation-types";
    import confirmationMessageMixin from "@/mixins/confirmationMessageMixin";

    export default {
        name: "UserProfile",
        title(){ return this.$t('Profile page');},
        components: {UpdatePasswordForm, OrganizerProfileForm, UserProfileForm},
        data() {
            return {
                hasOrganizerProfile: null,
            };
        },
        methods: {
            ...mapMutations('auth', [
                SET_USER,
            ]),
            async toggleOrganizerProfile(){
                if (this.user.is_organizer){
                    const {result} = await this.$_p_confirmAction(this.$t('Remove the organizer profile? All artists collections, conversations, vacancies will deleted'));
                    if (result){
                        this.$_p_formDelete('organizer-profiles/' + this.user.organizer_profile.id)
                            .then(() => {
                                this.hasOrganizerProfile = false;
                                this[SET_USER]({...this.user, ...{organizer_profile: null, is_organizer: false}});
                            });
                    } else {
                        this.hasOrganizerProfile = !this.hasOrganizerProfile;
                    }

                } else {
                    this.axios.post('organizer-profiles')
                        .then(res => {
                            if (201 === res.status){
                                this.hasOrganizerProfile = true;
                                this[SET_USER]({...this.user, ...{organizer_profile: res.data, is_organizer: true}});
                            }
                        });

                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
            }),
        },
        created() {
            this.hasOrganizerProfile = this.user.is_organizer;
            this.$Progress.finish();
        },
        mixins: [formHelperMixin, confirmationMessageMixin],
    };
</script>

<style scoped>
    .user-profile-form{
        margin-bottom: 20px;
    }
</style>