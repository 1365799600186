<template>
        <form action="#" @submit.prevent>
            <b-field :label="$t('Name')"
                     :message="$_p_formGetError('name')"
                     :type="{'is-danger': $_p_formHasError('name')}">

                <b-input @input="$_p_formClearError('name')"
                         @keyup.native.enter="handleUserUpdate"
                         v-model="user.name"
                         icon="user">
                </b-input>

            </b-field>

            <b-field :label="$t('Email')" >
                <b-input disabled :value="user.email" icon="envelope"></b-input>
            </b-field>

            <b-button @click="handleUserUpdate" type="is-primary" :loading="formBusy">
                {{$t('Save')}}
            </b-button>
        </form>

</template>

<script>
    import formHelperMixin from "@/mixins/formHelperMixin";
    import {UPDATE_USER} from "@/store/modules/auth/action-types";
    import {mapActions} from "vuex";

    export default {
        data: function(){
            return {
                user: {},
            };
        },
        mixins: [formHelperMixin],
        methods: {
            ...mapActions('auth', [
                UPDATE_USER,
            ]),
            handleUserUpdate(){
                this.$_p_formDispatch('updateUser', this.user)
                    .then(() => {
                        this.$buefy.toast.open({
                            message: this.$t('Changes saved'),
                            type: 'is-success',
                        });
                    });
            }
        },
        mounted() {
            this.user = {...this.$store.state.auth.user};
        },
        name: "UserProfileForm"
    };
</script>

<style scoped>

</style>