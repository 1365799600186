export default {
    methods: {
        async $_p_confirmAction(message, title = this.$t('Please confirm')){
            const {result, dialog} = await this.$buefy.dialog.confirm({
                message,
                title,
                cancelText: this.$t('Cancel'),
                confirmText: this.$t('Yes')
            });
            return {result, dialog};
        }
    },
};