<template>
    <form action="#" @submit.prevent>
        <h4 class="title is-4">{{$t('Organizer profile')}}</h4>

        <cities-list :has-error="$_p_formHasError('city')"
                     :errors="errors"
                     :error-message="$_p_formGetError('city')"
                     @selected="city => organizerProfile.city_id = city.id"
                     @clear-error="$_p_formClearError('city_id')"
                     :preselected-city-id="organizerProfile.city_id" />

        <b-field :label="$t('Workplace')"
                 :type="{'is-danger': $_p_formHasError('work_place')}"
                 :message="$_p_formGetError('work_place')">
            <b-input icon="home"
                     v-model="organizerProfile.work_place"
                     @keyup.native.enter="updateOrganizerProfile"
                     @input="$_p_formClearError('work_place')"> </b-input>
        </b-field>

        <b-field :label="$t('Position')"
                 :type="{'is-danger': $_p_formHasError('position')}"
                 :message="$_p_formGetError('position')">
            <b-input icon="briefcase"
                     @keyup.native.enter="updateOrganizerProfile"
                     v-model="organizerProfile.position"
                     @input="$_p_formClearError('position')"> </b-input>
        </b-field>

        <!--<b-field label="Рабочий телефон">
            <b-input icon="phone" :value="organizerProfile.work_phone"> </b-input>
        </b-field>

        <b-field label="Рабочий имеил">
            <b-input type="email" icon="envelope" :value="organizerProfile.work_email"> </b-input>
        </b-field>-->

        <b-button type="is-primary" @click="updateOrganizerProfile" :loading="formBusy">{{$t('Save')}}</b-button>
    </form>

</template>

<script>
    import formHelperMixin from "@/mixins/formHelperMixin";
    import CitiesList from "./parts/common/CitiesList";
    import {mapMutations, mapState} from "vuex";
    import {SET_USER} from "@/store/modules/auth/mutation-types";

    export default {
        components: {CitiesList},
        name: "OrganizeProfileForm",
        data: function () {
            return{
                organizerProfile: null,
            };
        },
        methods:{
            ...mapMutations('auth', [
                SET_USER,
            ]),
            updateOrganizerProfile(){
                this.$_p_formPut('organizer-profiles/' + this.organizerProfile.id, this.organizerProfile)
                    .then(res => {
                        this[SET_USER]({...this.user, ...{organizer_profile: res.data, is_organizer: true}});
                        this.$buefy.toast.open({
                            message: this.$t('Changes saved'),
                            type: 'is-success'
                        });
                    });
            },
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
            }),
        },
        created() {
            this.organizerProfile = {...this.user.organizer_profile};
        },
        mixins: [formHelperMixin],
    };
</script>

<style scoped>

</style>