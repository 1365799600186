<template>
    <form action="#" @submit.prevent>
        <form action="" name="update-password">
            <h4 class="title is-4">{{$t('Change password')}}</h4>

            <b-field :label="$t('Current password')"
                     :message="$_p_formGetError('current_password')"
                     :type="{'is-danger': $_p_formHasError('current_password')}">
                <b-input v-model="passwords.current_password" type="password" placeholder="******"> </b-input>
            </b-field>

            <b-field :label="$t('New password')"
                     :message="$_p_formGetError('password')"
                     :type="{'is-danger': $_p_formHasError('password')}">
                <b-input v-model="passwords.password" type="password" placeholder="******"> </b-input>
            </b-field>

            <b-field :label="$t('Confirm password')"
                     :message="$_p_formGetError('password')"
                     :type="{'is-danger': $_p_formHasError('password')}">
                <b-input v-model="passwords.password_confirmation"
                         type="password"
                         placeholder="******"
                        @keyup.native.enter="updatePassword"> </b-input>
            </b-field>

            <b-field>
                <b-button type="is-primary" @click="updatePassword" :loading="formBusy">{{$t('Change password')}}</b-button>
            </b-field>
        </form>

    </form>

</template>

<script>

    import formHelperMixin from "@/mixins/formHelperMixin";

    export default {
        data: function(){
            return {
                passwords: {
                    current_password: '',
                    password: '',
                    password_confirmation: '',
                },
                formBusy: false,
            };
        },
        methods: {
            updatePassword(){
                this.$_p_formPut('user-passwords', this.passwords)
                .then(() => {
                    Object.keys(this.passwords).map(key => {
                        this.passwords[key] = '';
                    });
                    this.errors = {};
                    this.$buefy.toast.open({
                        message: this.$t('Changes saved'),
                        type: 'is-success',
                    });
                });
            }
        },
        mixins:[formHelperMixin],
        name: "UpdatePasswordForm",
    };
</script>

<style scoped>

</style>